<template>
  <Container class="w-full my-16 overflow-x-hidden lg:my-24">
    <div v-if="size === 'desktop'"
         class="grid md:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-12 lg:gap-y-28"
    >
      <SectionBulletPointsSingle v-for="(item, i) in items"
                                 :key="i"
                                 :item="item"
      />
    </div>
    <Slider :mobile-per-view="1.2"
            :mobile-spacing="24"
            v-else
    >
      <SectionBulletPointsSingle v-for="(item, i) in items"
                                 :key="i"
                                 :item="item"
                                 class="flex-shrink-0 keen-slider__slide"
      />
    </Slider>
  </Container>
</template>
<script lang="ts" setup>
import { useDetectSize } from '../../composables/detect-size';
const size = useDetectSize();

interface bulletPointsProps {
  items: {
    title: string;
    copy: string;
    icon: string;
    color: string;
  }[];
}

defineProps<bulletPointsProps>();
</script>
